<template>
    <el-card shadow="hover" :body-style="{ padding: '0 20px' }">
        <div slot="header" class="clearfix">
            <span>最近项目</span>
            <!-- <el-radio-group style="float:right" v-model="selected" size="mini" @input="reasonSelect">
                <el-radio-button :label="r" v-for="r, i in reasonData" :key="i" />
            </el-radio-group> -->
        </div>
        <el-table :data="tableData" stripe style="width: 100%;margin-bottom:10px" height="380px" @row-click="rowClick">
            <el-table-column class="hidden-md-and-down" type="index" width="55" label="序号" align="center" />
            <el-table-column prop="projectName" label="项目名称" width="" show-overflow-tooltip />
            <!-- <el-table-column prop="projectCode" label="项目代码" width="" /> -->
            <el-table-column prop="contactName" label="联系人" width="100" show-overflow-tooltip/>
            <el-table-column class="hidden-md-and-down" prop="createdAt" label="创建时间" :formatter="datetimeFormatter" width="120" show-overflow-tooltip/>
        </el-table>
    </el-card>
</template>

<script>
export default {
    data() {
        return {
            selected: '',
            keywords: '',
            reasonData: [],
            tableData: []
        }
    },
    methods: {
        datetimeFormatter (v) {
            return new Date(v.createdAt).format('yyyy-MM-dd')
        },
        rowClick(row) {
            this.$router.push(`/main/choose/chlog/${row.id}`)
        },
        reasonSelect() {
            this.getData()
        },
        async getReason() {
            const resp = await this.$http.get('/project/types')
            this.reasonData = resp.data
            this.selected = this.reasonData[0]
            this.getData()
        },
        async getData() {
            const resp = await this.$http.get(`/choose/project?page=1&pageSize=10&status=0&type=${this.selected}`)
            this.tableData = resp.data.rows
        }
    },
    mounted() {
        this.getReason()
    }
}
</script>

