<template>
    <div>
        <el-row v-loading="loading" :gutter="10">
            <el-col :xs="24" :sm="12" :md="6">
                <count-card title="专家总数" :count="counts.expert_count" color="rgb(128, 176, 210)" />
            </el-col>
            <el-col :xs="24" :sm="12" :md="6">
                <count-card title="项目数" :count="counts.project_count" icon="el-icon-s-shop" color="rgb(141, 210, 198)" />
            </el-col>
            <el-col :xs="24" :sm="12" :md="6">
                <count-card title="专家抽取次数" :count="counts.finish_count" icon="el-icon-s-flag" />
            </el-col>
            <el-col :xs="24" :sm="12" :md="6">
                <count-card title="抽取专家合计" :count="counts.random_count" icon="el-icon-s-opportunity" color="#bd0b0b" />
            </el-col>
        </el-row>
        <div style="margin: 20px 0">
            <el-row :gutter="10">
                <el-col :xs="24" :sm="24" :md="16">
                    <projectTable />
                </el-col>
                <el-col :xs="24" :sm="24" :md="8">
                    <projectChart />
                </el-col>
            </el-row>
        </div>
        <div>
            <calendarHeatmap />
        </div>
        <el-row :gutter="20" style="margin: 40px 0 20px">
            <el-col :span="6">
                <div>
                    <el-statistic value="-" title="专家总数"></el-statistic>
                </div>
            </el-col>
            <el-col :span="6">
                <div>
                    <el-statistic title="任务总数">
                        <template slot="formatter"> - </template>
                    </el-statistic>
                </div>
            </el-col>
            <el-col :span="6">
                <div>
                    <el-statistic title="抽取次数">
                        <template slot="formatter"> - </template>
                    </el-statistic>
                </div>
            </el-col>
            <el-col :span="6">
                <div>
                    <el-statistic title="其他">
                        <template slot="formatter"> - </template>
                    </el-statistic>
                </div>
            </el-col>
        </el-row>
        <div style="padding: 10px">
            <el-divider content-position="center">已经到底了</el-divider>
        </div>
    </div>
</template>

<script>
import ProjectTable from './widgets/projectTable'
import ProjectChart from './widgets/projectChart'
import CalendarHeatmap from './widgets/calendarHeatmap'
export default {
    components: {
        ProjectTable,
        ProjectChart,
        CalendarHeatmap,
    },
    data() {
        return {
            loading: false,
            counts: {}
        }
    },
    methods: {
        getCountData() {
            this.$http.get('/dashboard/counts')
                .then(resp => {
                    this.counts = resp.data
                })
        }
    },
    mounted() {
        this.getCountData()
    }
}
</script>

<style  lang="scss" scoped>
    ::v-deep .el-col{
        padding: 10px 0;
    }
</style>
