<template>
    <el-card shadow="hover" :body-style="{ padding: '0 20px' }">
        <div slot="header" class="clearfix">
            <span>联系人抽取占比</span>
            <el-date-picker class="hidden-md-and-down" style="float:right;width:240px" size="mini" v-model="daterange" type="daterange" align="right"
                unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                :picker-options="pickerOptions">
            </el-date-picker>
        </div>
        <div style="width:100%">
            <v-chart autoresize :option="option" style="height: 390px" v-loading="loading" ref="agePieChart"></v-chart>
        </div>
    </el-card>
</template>

<script>
export default {
    name: 'projectChart',
    data() {
        return {
            loading: false,
            daterange: [],
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            option: {
                color: ['#de6e6a', '#5971c0', '#FCCE10', '#E87C25', '#B5C334'],
                // legend: {
                //     orient: 'vertical',
                //     x:'left', //可设定图例在左、右、居中
                //     y:'center', //可设定图例在上、下、居中
                // },
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)'
                },
                series: [
                    {
                        label: {
                            normal: {
                                formatter: function (obj) {
                                    return obj.data.name
                                },
                            },
                            show: false
                        },
                        name: '项目占比',
                        type: 'pie',
                        radius: '55%',
                        center: ['50%', '50%'],
                        data: [
                            // { value: Math.floor(Math.random() * 1000), name: '软件开发' },
                            // { value: Math.floor(Math.random() * 1000), name: '技术服务' },
                            // { value: Math.floor(Math.random() * 1000), name: '医疗卫生服务' },
                            // { value: Math.floor(Math.random() * 1000), name: '装修工程' },
                            // { value: Math.floor(Math.random() * 1000), name: '印刷和出版' },
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            }
        }
    },
    methods: {
        async getData() {
            this.loading = true
            const resp = await this.$http.get('/statistics/choose/contact')
            console.log(resp.data)
            var newArray = []
            resp.data.data.forEach(function (item) {
                newArray.push({name:item.name,value:item.count})
            })
            this.option.series[0].data = newArray
            this.$refs['agePieChart'].setOption(this.option)
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>
